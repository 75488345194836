<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      sticky
      color="secondary"
      elevation="5"
      height="80"
    >
      <v-container fluid>
        <v-row
          no-gutters
          align="center"
          :justify="
            $vuetify.breakpoint.smAndDown ? 'space-between' : 'space-between'
          "
        >
          <v-col cols="4" md="2">
            <router-link
              :to="tableId && $route.name !== 'ThankYou' ? `/menu` : ''"
            >
              <base-img
                class="logo mb-1"
                style="max-width: 170px"
                :src="getLogo"
                contain
              />
            </router-link>
          </v-col>
          <v-col cols="4" md="3">
            <v-row class="container__menu justify-end align-center">
              <UserMenu />
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import { axiosInstance } from "@/plugins/axios";
import { i18n } from "@/plugins/i18n";
import { languages, loadLanguageAsync } from "@/plugins/lang";
import { settingCtrl } from "@/controllers";

import { settingsDigitalLetterStoreGetters } from "@/store/modules/settingsDigitalLetter/constNames";

export default {
  name: "HomeAppBar",
  components: {
    UserMenu: () => import("@/components/userOrLogin/UserMenu.vue"),
  },
  computed: {
    getSettingsDigitalLetterImages() {
      return this.$store.getters[settingsDigitalLetterStoreGetters.GET_IMAGES];
    },
    getLogo() {
      return (
        this.getSettingsDigitalLetterImages.logo ||
        require("@/assets/logo-foodeo.png")
      );
    },
  },
  data: () => ({
    tableId: "",
    speedDial: false,
    lang: localStorage.getItem("lang") || navigator.language.split("-")[0],
    languages: { ...languages },
  }),
  async mounted() {
    this.getTableId();
    const globalConfigs = await settingCtrl.getGlobalSetting();
    const { language_web_menu, currency, timezone } = globalConfigs;
    if (language_web_menu) {
      this.lang = language_web_menu;
      await this.$store.dispatch("user/setCurrency", currency);
    }
    if (timezone) await this.$store.dispatch("user/setTimezone", timezone);
    await this.setLang(this.lang);
  },
  watch: {
    $route() {
      this.getTableId();
    },
    deep: true,
  },
  methods: {
    async setLang(lang, reloadWindow = false) {
      this.lang = lang;
      await loadLanguageAsync(lang)
        .then(() => {
          let formData = new FormData();
          formData.append("language_web_menu", lang);
          axiosInstance
            .put("api/v1/settings/global/", formData, {
              headers: {
                Authorization: `Token ${process.env.VUE_APP_TOKEN}`,
                "Content-Type": "application/json",
                Site: process.env.VUE_APP_SITE,
                Client: "app",
              },
            })
            .then(() => {
              if (!reloadWindow) return;
              i18n.locale = this.lang;
              location.reload();
            });
        })
        .catch(() => {
          const payload = {
            msg: i18n.t("languageNotAdded"),
            success: false,
          };
          this.$store.commit("user/OPEN_SNACKBAR", payload);
        });
    },

    getTableId() {
      this.tableId = localStorage.getItem("tableId");
    },
  },
};
</script>

<style lang="sass">
$tab-font-size: map-deep-get($headings, 'body-2', 'size') !important
.logo
  width: 145px
  height: 60px

#home-app-bar
  z-index: 100

  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

  .v-tab
    &::before
      display: none
</style>
